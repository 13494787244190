













































import { PageBase } from "@/core/models/shared";
import { AlunoTurmaService } from "@/core/services/geral";
import { Component } from "vue-property-decorator";

@Component
export default class RelatorioFinanceiro extends PageBase{

    service: AlunoTurmaService = new AlunoTurmaService();
    lista: any[] = [];
    filtro: any = {
        turmaId: null,
        alunoId: null,
        pago: null,
        vencimentoDataInicio: '',
        vencimentoDataFim: '',
        pagamentoDataInicio: '',
        pagamentoDataFim: ''
    }
    overlay: boolean = false;

    mounted() {

        this.filtro = {
            turmaId: this.$route.query.turmaId,
            alunoId: this.$route.query.alunoId,
            pago: this.$route.query.pago,
            vencimentoDataInicio: this.$route.query.vencimentoDataInicio,
            vencimentoDataFim: this.$route.query.vencimentoDataFim,
            pagamentoDataInicio: this.$route.query.pagamentoDataInicio,
            pagamentoDataFim: this.$route.query.pagamentoDataFim,
        }
        
        this.overlay = true;

        this.service.RelatorioFinanceiroAgrupadoAluno(this.filtro.vencimentoDataInicio, this.filtro.vencimentoDataFim, this.filtro.pagamentoDataInicio, this.filtro.pagamentoDataFim, this.filtro.turmaId, this.filtro.alunoId, this.filtro.pago)
        .then(
            res =>{
                this.lista = res.data;
            },
            err =>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }
}
